html { 
    height: 100%; 
}
body { 
    height: auto;
    margin: 0; 
    font-family: Roboto, sans-serif; 
}

main{
    min-height: 80%;
}

.navbar {
    background-color: #303841;
    margin: 0;
    height: 80px;
}

article, .content{  
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    padding:8px 16px!important;
}

.head-title{
    color: #FF5722;
    font-weight: bolder;
    padding: 10px;
    background-color: rgb(236, 236, 236);
}

.main-content{
    margin: 0;
    padding: 0;
    padding-right: 30px;
}

.sidebar-content{
    padding: 1px 16px;
    width: 300px;
}

.space {
    padding: 0px;
    padding-top: 40px;
    margin: 0px;
}

.coluna-container{
    display: table; 
    width: 100%;
}

.coluna{
    display: table-cell;
}

@media screen and (max-width: 1080px) {

    footer div {
        display: block !important;
        padding: 10px;
    }
    footer li {
        text-align: center !important;
    }

    .navbar li, .navbar-secondary button {
        display: none;
    }

    #navbar-logo{
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .main-content {
      padding-right: 0px;
    }
    
    .sidebar-content {
        margin-top: 20px;
        margin-left: 0;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
        width: 100%;
    }

    .coluna{
        display: block;
        max-width: 100%;
    }
    
}


.content-box-grey {
    position: relative;
    z-index: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #EEEEEE;
    border: 1px solid rgba(48, 56, 65, 0.15);
    border-radius: 10px;
    padding-bottom: 15px;
}

.navbar-logo {
    width: 120px;
    margin-top: -5px;
    padding: 0;
}

.navbar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.navbar > .content > li {
    float: left;
  }

.navbar > .content > li  a , .navbar-secondary > li{
    display: block;
    color: white;
    text-align: center;
    padding: 20px;
    text-decoration: none;
    font-size: 14px;
}

.navbar-secondary > li{
    float: right;
}


.titulo-content-box {
    z-index: 2;
    position: relative;
    display: block;
    text-align: center;
    width: max-content;
    padding: 5px;
    background-color: #788A8B;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: -15px;
    margin-bottom: 10px;
}


.content-box-white{
    z-index: 2;
    background-color: white;
    width: auto;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
}

footer {
    margin: 0;
    display: block;
    width: 100%;
    background-color: #303841;
    color: white;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
  
footer div {
    display: inline-table;
}

footer ul {
    list-style-type: none;
    padding-right: 30px;
    margin-top: 10px;
}

footer li{
    text-align: center;
    display: list-item;
    padding-top: 7px;
}

footer u {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
}

footer u::before{
    content: '';
    position: absolute;
    margin-top:32px;
    background-color: #FF5722;
    height: 2px;
    box-sizing: border-box;
    width: 25px;
}

footer a {
    color: rgb(255, 255, 255);
    font-size: 16px;
    text-decoration: none;
    margin-left: 8px;
} 

footer a:hover {
    color: #FF5722;
    font-size: 16px;
    text-decoration: none;
}

footer p{
    margin: 0;
    font-size: 15px;
}

.box{
    margin: 0;
}

.box button{
    background-color: #303841;
    z-index: 2;
    position: relative;
    display: block;
    text-align: center;
    padding: 7px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    float: right;
    margin-right: 15px;
    margin-top: -15px;
    font-size: 18px;
    border-style: none;
}

.text{
    text-align: justify;
    text-justify: inter-word;
    padding-left: 20px;
    padding-right: 20px;
    word-break: break-all;
    line-height: normal;
}

.loading {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 45px;
    height: 45px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(120, 138, 139);
    border-color: rgb(120, 138, 139) transparent #fff transparent;
    animation: lds-dual-ring 0.6s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

input:invalid:focus {
    background-image: linear-gradient(rgba(255, 0, 0, 0.1), rgb(255, 0, 0, 0.1));
  }